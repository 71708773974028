// extracted by mini-css-extract-plugin
export var alt = "CityPage-module--alt--ea465";
export var container = "CityPage-module--container--ff960";
export var desktopOnly = "CityPage-module--desktopOnly--30142";
export var main = "CityPage-module--main--cc755";
export var mobileOnly = "CityPage-module--mobileOnly--afb68";
export var subTitle2 = "CityPage-module--subTitle2--76cc8";
export var textLeft = "CityPage-module--textLeft--0cca3";
export var title = "CityPage-module--title--5a577";
export var title2 = "CityPage-module--title2--df841";
export var visuallyHidden = "CityPage-module--visuallyHidden--61fc4";